import React, { useState, useEffect } from 'react';
import './FadingPhotoCarousel.css'; // Make sure to create this CSS file

const FadingPhotoCarousel = ({ images, interval = 5000 }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, interval);

        return () => clearInterval(timer);
    }, [images.length, interval]);

    return (
        <div className="carousel">
            {images.map((image, index) => (
                <div
                    key={image}
                    className={`photo ${index === currentImageIndex ? 'visible' : ''}`}
                    style={{ backgroundImage: `url(${image})` }}
                ></div>
            ))}
        </div>
    );
};

export default FadingPhotoCarousel;
