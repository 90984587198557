import React from "react";
import "./App.css";
import FadingPhotoCarousel from "../images/Carousel";
import { imagelist } from "../images/imagelist";

function App() {
  const isItFridayYet = () => {
    const now = new Date();
    const today = now.getDay();
    const dayWanted = 5; // Friday
    const offset = today - dayWanted;

    switch (offset) {
      case -1:
      case 6:
        return "Almost.";
      case 0:
        return "Yep.";
      case 1:
      case -6:
        return "You just missed it.";
      default:
        return "Nope.";
    }
  };

  return (
    <div id="outer">
      <div id="upper">
        <div id="content">
          <span className="big">{isItFridayYet()}</span>
        </div>
      </div>
      <div id="lower">
        <div id="footer">
          {/* Dropped by AdSense for "low quality content". Can you believe that? */}
        </div>
      </div>
      <FadingPhotoCarousel images={imagelist} />
    </div>
  );
}

export default App;
